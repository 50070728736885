import { FormFieldBase } from '@app/core/models/form-field-base.model';
import { CkeditorPayload, DynamicFieldAttributeInsertExecutePayload, DynamicFieldCreateOrUpdateExecutePayload, DynamicFieldExecutePayload } from './ckeditor-payload.model';

import { pick,isEqual } from 'lodash-es';
import { ParticipantModel } from './participant.model';
export class DynamicField extends FormFieldBase {

    constructor(options: {} = {}) {
        super(options);
        this.fieldType = 'dynamicField';
    }
    getAttributePayloadForCk(): { attributeKey: string; value: any }[] {
        console.log('getAttributePayloadForCk --> ',this)
        return [
            {
                attributeKey: this.getCkAlias('dynamicFieldName'),
                value: this.label as string || ''
            },
            {
                attributeKey: this.getCkAlias('type'),
                value: `${this.controlType}:${this.type}`
            },
            {
                attributeKey: this.getCkAlias('optionalMode'),
                value: this.required ?  'required' : 'optional'
            }
        ];
    }

    getCommandPayloadToUpdateAttribute(data: {
        key: string,
        attributes: {
            attributeKey: string;
            value: string
        }[]
    }): DynamicFieldAttributeInsertExecutePayload {
        return new DynamicFieldAttributeInsertExecutePayload({
            payload: data
        });
    }

    getCommandPayloadToCreate(value: string): CkeditorPayload {
        return new DynamicFieldExecutePayload({
            payload: {
                key: this.key,
                label: this.label,
                value: value,
                type: `${this.controlType}:${this.type}`,
                metadata: (this.options || []).join('||')
            }
        });
    }

    getCommandPayloadToCreateOrUpdate(data: {
        key: string,
        attributes: {
            attributeKey: string;
            value: any
        }[]
    }): DynamicFieldCreateOrUpdateExecutePayload {
        return new DynamicFieldCreateOrUpdateExecutePayload({
            payload: data
        });
    }

    getMarkerName(): string {
        return this.key
    }

    getPlaceHolder(option?:{participants?:ParticipantModel[]}): string {
        return this.required ? `<span class="label-fake-mask">${this.label}</span>` : ``;
    }
    isEqual(data:Partial<FormFieldBase>):boolean{
        const availableKeys = ['label','controlType','fieldType','type']
        const currValue = pick(this,availableKeys);
        const newValue = pick(data,availableKeys);
        return isEqual(currValue,newValue)

    }
}
