export class DataFieldConstant {

    static CLIENT_FIELDS = [
        {
            key: 'client_name',
            label: 'Client Name',
            controlType: 'textbox',
            path: 'mainContact.name',
            type: 'text'
        },
        {
            key: 'client_id_no',
            label: 'Client ID No.',
            controlType: 'textbox',
            path: 'mainContact.nricNumber',
            type: 'text'
        },
        {
            key: 'client_passport_number',
            label: 'Client Passport Number',
            controlType: 'textbox',
            path: 'mainContact.passportNumber',
            type: 'text'
        },
        {
            key: 'client_dob',
            label: 'Client DOB',
            controlType: 'date',
            path: 'mainContact.dateOfBirth',
            config: {
                format: 'DD/MM/YYYY'
            },
            type: 'shortDate'
        },
        {
            key: 'client_phone_number',
            label: 'Client Phone Number',
            controlType: 'textbox',
            path: 'mainContact.phoneNumber',
            type: 'text'
        },
        {
            key: 'client_email',
            label: 'Client Email',
            controlType: 'textbox',
            path: 'mainContact.email',
            type: 'text'
        },
        {
            key: 'client_address',
            label: 'Client Address',
            controlType: 'textbox',
            path: 'address',
            type: 'text'
        },
        {
            key: 'client_company_name',
            label: 'Client Company Name',
            controlType: 'textbox',
            path: 'companyName',
            type: 'text'
        },
        {
            key: 'client_uen_number',
            label: 'Client UEN Number',
            controlType: 'textbox',
            path: 'fields.uenNumber',
            type: 'text'
        }
    ];

    static ITEM_FIELDS = [
        {
            key: 'item_name',
            label: 'Property Title',
            controlType: 'textbox',
            path: 'name',
            type: 'text'
        },
        {
            key: 'item_address',
            label: 'Property Address',
            controlType: 'textbox',
            path: 'address',
            type: 'text'
        }
    ];

    static SALESPERSON_FIELDS = [
        {
            key: 'co_broke_salesperson_name',
            label: 'Co-broke Salesperson Name',
            controlType: 'textbox',
            path: 'profile.salespersonName',
            type: 'text'
        },
        {
            key: 'co_broke_salesperson_reg_no',
            label: 'Co-broke Salesperson Reg. No.',
            controlType: 'textbox',
            path: 'profile.registrationNumber',
            type: 'text'
        },
        {
            key: 'co_broke_salesperson_email',
            label: 'Co-broke Salesperson Email',
            controlType: 'textbox',
            path: 'email',
            type: 'text'
        },
        {
            key: 'co_broke_salesperson_phone_number',
            label: 'Co-broke Salesperson Phone Number',
            controlType: 'textbox',
            path: 'phoneNumber',
            type: 'text'
        },
        {
            key: 'co_broke_estate_agent_name',
            label: 'Co-broke Estate Agent Name',
            controlType: 'textbox',
            path: 'profile.estateAgentName',
            type: 'text'
        },
        {
            key: 'co_broke_estate_agent_license_no',
            label: 'Co-broke Estate Agent License No.',
            controlType: 'textbox',
            path: 'profile.estateAgentLicenseNumber',
            type: 'text'
        },
        {
            key: 'co_broke_estate_agent_address',
            label: 'Co-broke Estate Agent Address',
            controlType: 'textbox',
            path: 'fullAddress',
            type: 'text'
        }
    ];

    static USER_FIELDS = [
        {
            key: 'user_name',
            label: 'BU Member Name',
            controlType: 'textbox',
            path: 'name',
            type: 'text'
        },
        {
            key: 'user_registration_number',
            label: 'BU Member Registration Number',
            controlType: 'textbox',
            path: 'profile.registrationNumber',
            type: 'text'
        },
        {
            key: 'user_email',
            label: 'BU Member Email',
            controlType: 'textbox',
            path: 'email',
            type: 'text'
        },
        {
            key: 'user_phone',
            label: 'BU Member Phone Number',
            controlType: 'textbox',
            path: 'profile.phone',
            type: 'text'
        },
        {
            key: 'user_designation',
            label: 'BU Designation',
            controlType: 'textbox',
            path: 'profile.jobTitle',
            type: 'text'
        }
    ];

    static getLabelName(groupName: string, label: string): string {
        return groupName + '.' + label;
    }
}

