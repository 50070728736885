import { FormFieldBase } from '@app/core/models/form-field-base.model';
import { ContentBuilderFieldType } from '@app/core/types/content-builder-field-type';
import { ParticipantModel } from './participant.model';
export interface ParticipantFieldConfigInterface {
    markupId: string;
    isForward?: boolean;
    html?: string;
}
export class ParticipantFieldConfig implements ParticipantFieldConfigInterface {
    markupId: string;
    isForward?: boolean;
    html: string;

    constructor(data: ParticipantFieldConfigInterface) {
        this.markupId = data && data.markupId;
        this.isForward = data?.isForward;
        this.html = data?.html || '';
    }
}
export class ParticipantField extends FormFieldBase {
    constructor(options = {}) {
        super(options);
        this.config = new ParticipantFieldConfig(options['config']);
        this.count = 1;
    }
    getPlaceHolder(option?:{participants?:ParticipantModel[]}): string {
        
        let owner = null;
        console.log('getPlaceHolder --> ',this.config.markupId,option?.participants)
        if (this.config.markupId && option?.participants) {
            const participant = option.participants.find(item => item.markupId === this.config.markupId)
            if(participant){
                owner = participant.name;
            }
            
        }

        // return this.config?.html ? this.config?.html : `<div class="fake-label">${this.label}</div>`;
        // tslint:disable-next-line: max-line-length
        return (
            `<span class="label-fake-mask">${this.label}` +
            (owner
                ? `<span style="margin-left:8px" class="redr-inline-field__tag">${owner}</span>`
                : '') +
            `</span>`
        );
    }

    getMarkerName(): string {
        return `${this.key}`;
    }
}
