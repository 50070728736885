import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FileUploaderInterface, FileUploaderModel } from '@modules/upload/file-uploader.model';
import { combineLatest, Observable } from 'rxjs';
import { StorageApiService } from '@shared/services/storage-api.service';
import { ErrorResponseInterface } from '@core/models/error-response.interface';

@Directive({
    selector: '[appUpload]'
})
export class UploadDirective implements OnInit, OnChanges {
    static imageMineTypes = ['image/jpg', 'image/jpeg', 'image/png'];

    @Input() disabled = false;
    @Input() isUploadImmediately = false;
    @Input() isReplaced = false;
    @Input() allowedMimeType: Array<string>;
    @Input() maxFiles = 15;
    @Input() maxFileSize = 15 * 1024 * 1024;
    @Input() uploadToFolder = 'asset';
    @Input() existFileKeys: Array<string>;
    @Input() allowExtensions = ['png', 'jpeg', 'jpg'];
    @Input() isPublic = false;
    @Input() apiGetUploadLink: (data: { type; name; acl? }) => Observable<any>;
    @Input() customizeFileNameFn?: (file:File,fileIndex?:number) => string
    @Output() all = new EventEmitter<FileUploaderInterface[]>();
    @Output() added = new EventEmitter<FileUploaderInterface[]>();
    @Output() removed = new EventEmitter<FileUploaderInterface[]>();
    @Output() lastUploaded = new EventEmitter<FileUploaderInterface[]>();
    @Output() begin = new EventEmitter<any>();
    @Output() finished = new EventEmitter<any>();
    @Output() beginFile = new EventEmitter<FileUploaderInterface>();
    @Output() finishedFile = new EventEmitter<FileUploaderInterface>();
    @Output() fullSlot = new EventEmitter<void>();
    files = [];
    fileId = 0;
    allFiles: FileUploaderInterface[] = [];
    addedFiles: FileUploaderInterface[] = [];
    lastUploadedFiles: FileUploaderInterface[] = [];

    constructor(
        private elementRef: ElementRef,
        private storageApiService: StorageApiService) {
    }

    ngOnInit(): void {
        this.patchExistFiles();
    }

    ngOnChanges(): void {
        this.patchExistFiles();
    }

    @HostListener('change')
    onChange(files?: any) {
        if (this.disabled) {
            return;
        }
        if (files) {
            this.files = Array.from(files);
        } else {
            this.files = Array.from(this.elementRef.nativeElement.files);
        }
        this.elementRef.nativeElement.value = '';


        if (this.isReplaced) {
            this.allFiles = [];
        }

        this.validateFiles();
        this.loadFiles();

        if (this.isUploadImmediately) {
            this.upload();
        }
    }

    validateFiles() {
        this.addedFiles = [];
        const numberRemainingSlots = this.countRemainingSlots();
        if (numberRemainingSlots < this.files.length) {
            this.fullSlot.emit();
            // return;
        }
        const n = this.files.length < numberRemainingSlots ? this.files.length : numberRemainingSlots;
        for (let i = 0; i < n; i++) {
            const fileUploader = new FileUploaderModel();
            fileUploader.id = this.fileId++;
            fileUploader.file = this.files[i];
            if(this.customizeFileNameFn){
                const fullnamePart = this.files[i].name.split('.') as string[];
                const ext = fullnamePart.pop()
                 fileUploader.file = new File([this.files[i]],
                this.customizeFileNameFn(this.files[i],i)+'.'+ext,
                {type: this.files[i].type});
            }else{
                fileUploader.file = this.files[i]; 
            }
            // fileUploader.file = this.files[i];
            // fileUploader.file = new File([this.files[i]],
            //     (new Date().getTime()) + (Math.random()).toString().substr(2, 10) + '.' + this.files[i].name.split('.').pop(),
            //     {type: this.files[i].type});

            let error = false;

            // const nameRegex = /[`!@#$%^&*+=;':",.<>?~]/;
            // if (nameRegex.test(this.files[i].name.split('.').shift())) {
            //     fileUploader.error = {
            //         status: true,
            //         message: 'validator.name_has_special_character'
            //     };
            //     error = true;
            // }
            const regex = new RegExp(`(.*?)\.(${this.allowExtensions.join('|')})$`);
            if (!regex.test(this.files[i].name.toLowerCase()) ||
                (this.allowedMimeType && !this.allowedMimeType.includes(this.files[i].type))) {
                fileUploader.error = {
                    status: true,
                    message: 'validator.file_type_not_allow'
                };
                error = true;
            }

            if (this.files[i].size > this.maxFileSize) {
                fileUploader.error = {
                    status: true,
                    message: 'validator.file_type_too_large'
                };
                error = true;
            }
            if (error === true) {
                this.finishedFile.emit(fileUploader);
            }
            this.addedFiles.push(fileUploader);
            this.allFiles.push(fileUploader);
        }
        this.all.emit(this.allFiles);
        this.added.emit(this.addedFiles);
    }

    loadFiles() {
        this.allFiles.filter(f => !f.error.status && !f.isExistFile)
            .forEach(fileUploader => {
                const reader = new FileReader();
                reader.addEventListener(
                    'load',
                    (event: any) => {
                        fileUploader.data = event.target.result;
                    },
                    false
                );
                reader.readAsDataURL(fileUploader.file);
            });
    }

    upload() {
        this.begin.emit();
        this.lastUploadedFiles = [];
        const requests = [];
        this.allFiles.filter(f => !f.completed && !f.error.status && !f.isExistFile)
            .forEach((fileUploader) => {
                requests.push(this.uploadFile(fileUploader));
            });
        if (!requests.length) {
            this.finished.emit();
            return;
        }
        combineLatest(requests).subscribe(() => {
            this.lastUploaded.emit(this.lastUploadedFiles);
            this.all.emit(this.allFiles);
            this.finished.emit();
        });
    }

    uploadFile(fileUploader) {
        return new Observable(ob => {
            fileUploader.uploading = true;
            this.beginFile.emit(fileUploader);
            const payload = {type: this.uploadToFolder, name:fileUploader.file.name };
            if (this.isPublic) {
                payload['acl'] = 'public-read';
            }
            console.log('payload -->',payload);
            let apiGetUploadLink = (data) => this.storageApiService.getUploadLink(data);
            if (this.apiGetUploadLink) {
                apiGetUploadLink = (data) => this.apiGetUploadLink(data);
            }
            apiGetUploadLink(payload)
                .subscribe(linkInfo => {
                    fileUploader.key = linkInfo.fields.key;
                    const uploadData = linkInfo;
                    if (this.isPublic) {
                        uploadData.isPublic = this.isPublic;
                        fileUploader.url = linkInfo.url;
                        fileUploader.fullKeyName = linkInfo.fields.fullKeyName;
                    }
                    uploadData.file = fileUploader.file;
                    this.storageApiService.uploadFile(uploadData)
                        .subscribe({
                            next: () => {
                                fileUploader.uploading = false;
                                fileUploader.completed = true;
                                this.lastUploadedFiles.push(fileUploader);
                                this.finishedFile.emit(fileUploader);
                                ob.next();
                                ob.complete();
                            },
                            error: (error: ErrorResponseInterface) => {
                                fileUploader.uploading = false;
                                fileUploader.error = {
                                    status: true,
                                    message: error.message
                                };
                                this.finishedFile.emit(fileUploader);
                                ob.next();
                                ob.complete();
                            }
                        });
                });
        });
    }

    countRemainingSlots() {
        return this.maxFiles - this.allFiles.length;
    }

    clear() {
        this.removed.emit(this.allFiles);
        this.allFiles = [];
        this.all.emit(this.allFiles);
    }

    deleteFile(id): void {
        const index = this.allFiles.findIndex((fileUploader) => {
            return id === fileUploader.id;
        });
        if (index > -1) {
            this.removed.emit([this.allFiles[index]]);
            this.allFiles.splice(index, 1);
            this.all.emit(this.allFiles);
        }
    }

    patchExistFiles() {
        if (!this.existFileKeys || !this.existFileKeys.length) {
            return;
        }
        this.allFiles = [];
        this.existFileKeys.forEach((key) => {
            const fileUploader = new FileUploaderModel();
            fileUploader.id = this.fileId++;
            fileUploader.key = key;
            fileUploader.isExistFile = true;
            fileUploader.completed = true;
            fileUploader.file = {
                name: key.split('/').slice(-1).pop()
            };
            fileUploader.data = key;
            this.allFiles.push(fileUploader);

            // this.storageApiService.getPublicLink({name: key}).subscribe((info => {
            //     fileUploader.file.size = info.contentLength;
            //     fileUploader.file.type = info.contentType;
            //     fileUploader.data = info.objectUrl;
            //     this.allFiles.push(fileUploader);
            //     this.all.emit(this.allFiles);
            // }));
        });
        this.all.emit(this.allFiles);
    }
}
