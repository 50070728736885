import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener, Inject, Input, OnDestroy, Renderer2 } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
function isHTMLString(str: string): boolean {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}
export function coerceHtmlStringProperty(val): string {
  let input = '';
  if (val) {
      input = typeof val === 'string' ? val : String(val);
  }
  if (isHTMLString(input)) {
      return input;
  } else {
      return '<span>' + input + '</span>';
  }
}
@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class ContentEditableBase implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @HostBinding('class.red-contenteditable') hostClass = true;
  @Input('safeValue')
  get value(): string | null {
    return this.elementRef.nativeElement.innerHTML;
  }
  set value(val: string | null) {
    this.writeValue(val || '');
  }
  @Input() get  multiParagraph(){
    return this._multiParagraph
  }
  set multiParagraph(val){
    this._multiParagraph = coerceBooleanProperty(val)
  }
  private _multiParagraph = false
  protected observer?: MutationObserver;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onTouched = () => {};

  protected onChange = (_value: string) => {
    console.log('onChange --> ',_value)
  };

  constructor(@Inject(ElementRef) protected readonly elementRef: ElementRef<Element>, @Inject(Renderer2) protected readonly renderer: Renderer2) {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'contenteditable', 'true');
  }
  @HostListener('keydown',['$event'])
  onKeyDown(event: KeyboardEvent): boolean | void {
      // Check if Enter is pressed
  if (event.key === 'Enter') {
    if (event.shiftKey) {
      // Handle Shift + Enter (Insert line break, like in <br>)
      this.insertLineBreakAtCaret();
    } else {
      // Handle simple Enter (Insert <p> tag)
      event.preventDefault(); // Prevent default behavior (adding <div> or newline)
      if(this.multiParagraph){
        this.insertParagraphAtCaret();
      }else{
        this.insertLineBreakAtCaret();
      }
     
    }
  }
  }
  @HostListener('input',['$event'])
  onInput(event:Event) {
    this.observer?.disconnect();
    // this.onChange(this.elementRef.nativeElement.innerHTML);
    // const inputElement = event.target as HTMLElement;
    const valueTrimed = this.elementRef.nativeElement.innerHTML.trim(); // Update internal value

    // Check if the content is empty
    if (valueTrimed === '' || valueTrimed === '<br>') {
      console.log('hello createEmptyParagraph from onInput')
      this.createEmptyParagraph();
    }

    this.onChange(this.elementRef.nativeElement.innerHTML); // Notify Angular forms of the change
    this.onTouched(); // Mark as touched
  }

  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }

  writeValue(value: string) {
    console.log('hello  from writeValue',value)
    if (!value || value === '' || value === '<br>') {
      this.createEmptyParagraph();
    } else {
      if(value === this.value){
        return;
      }
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML',coerceHtmlStringProperty(value)  );
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    event.preventDefault(); // Prevent the default paste action

    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedHtml = clipboardData.getData('text/html') || clipboardData.getData('text/plain');

    // Create a temporary DOM element to strip out unwanted attributes
    const tempElement = document.createElement('div');
    tempElement.innerHTML = pastedHtml;
    // Remove styles, classes, and any meta tags
    this.sanitizeContent(tempElement);

    // Insert the cleaned content at the caret position
    this.insertCleanedContent(tempElement.innerHTML);
    // Notify content form have changed
    this.onChange(this.elementRef.nativeElement.innerHTML);
    this.onTouched();
  }
  sanitizeContent(element: HTMLElement): void {
    // Remove all <meta> tags and unwanted attributes
    const metaTags = element.querySelectorAll('meta');
    metaTags.forEach((meta) => meta.remove());

    const elements = element.querySelectorAll('*');
    elements.forEach((el) => {
      el.removeAttribute('style');
      el.removeAttribute('class');
    });
  }

  insertCleanedContent(cleanedHtml: string): void {
    const selection = window.getSelection();
    
    if (!selection || !selection.rangeCount) {
      return; // No valid selection, exit
    }

    const range = selection.getRangeAt(0); // Get the current range (caret position)

    // Create a temporary element to hold the cleaned HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = cleanedHtml;

    // Insert each child node of the tempDiv into the range
    while (tempDiv.firstChild) {
      range.insertNode(tempDiv.firstChild); // Insert the child node at the caret position
    }

    // Move the caret to the end of the inserted content
    range.collapse(false); // Collapse the range to the end
    selection.removeAllRanges();
    selection.addRange(range);
  }
  // Create an empty paragraph if the content is empty
  createEmptyParagraph(): void {
    console.log('hello createEmptyParagraph')
    const value = '<p><br></p>';
    // this.value = value; // Update internal value
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', value ); // Insert a default empty <p>
  }
  registerOnChange(onChange: (value: string) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'contenteditable', String(!disabled));
  }
  insertParagraphAtCaret(): void {
    
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const parentElement = range.commonAncestorContainer.parentElement;

    // Check if we are inside a <p> tag already
    if (parentElement.tagName.toLowerCase() === 'p') {
      // Move the caret outside the <p> before inserting a new one
      const editableElement = this.elementRef.nativeElement;
      const newP = document.createElement('p');
      newP.innerHTML = '<br>'; // Empty <p> with <br> for empty line

      // Insert the new <p> after the current one
      parentElement.insertAdjacentElement('afterend', newP);

      // Move the caret to the new <p>
      const newRange = document.createRange();
      newRange.setStart(newP, 0);
      newRange.setEnd(newP, 0);
      selection.removeAllRanges();
      selection.addRange(newRange);
    } else {
      // If not inside a <p>, just insert a new one at the caret position
      const p = document.createElement('p');
      p.innerHTML = '<br>'; // Empty <p> for a new line

      range.deleteContents();
      range.insertNode(p);

      // Move the caret after the new <p>
      range.setStartAfter(p);
      range.setEndAfter(p);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
  insertLineBreakAtCaret(): void {
    const selection = window.getSelection();
    // console.log('insertLineBreakAtCaret',selection,!selection.rangeCount)
    if (!selection || !selection.rangeCount) return;
  
    const range = selection.getRangeAt(0);
    const br = document.createElement('br');
    const secondBr = document.createElement('br'); // Add a second <br>

    range.deleteContents(); // Delete any selected content
    range.insertNode(br);   // Insert the first <br> tag
    range.insertNode(secondBr); // Insert the second <br> tag to ensure the line break

    // Move the caret after the second <br>
    const newRange = document.createRange();
    newRange.setStartAfter(secondBr);
    newRange.setEndAfter(secondBr);

    selection.removeAllRanges();
    selection.addRange(newRange);
  }
  // updateContent(value:string): void {
  //   console.log('updateContent')
  //   if (!value || value === '' || value === '<br>') {
  //     this.createEmptyParagraph();
  //   } else {
  //     this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', value );
  //   }
  // }
  ngAfterViewInit(): void {
    this.observer = new MutationObserver(() => {
      this.onChange(this.elementRef.nativeElement.innerHTML);
    });

    this.observer.observe(this.elementRef.nativeElement, {
      characterData: true,
      childList: true,
      subtree: true,
    });
  }
  ngOnDestroy(): void {
    this.observer?.disconnect();
  }
}
