import { DynamicField } from './dynamic-field.model';
import { FormFieldBase } from '@app/core/models/form-field-base.model';
import { CkeditorPayload, DynamicImageAttributeInsertExecutePayload, DynamicImageCreateOrUpdateExecutePayload, DynamicImageExecutePayload } from './ckeditor-payload.model';
import { ParticipantModel } from './participant.model';

export class DynamicFormFile extends DynamicField {
    controlType = 'file';
    type: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options['type'] || '';
    }
    getAttributePayloadForCk(): { attributeKey: string; value: string }[] {
        return [
            {
                attributeKey: 'label',
                value: this.label as string || ''
            }
        ];
    }

    getCkAlias(keyName: string): string {
        if (keyName === 'value') {
            return 'src';
        }
        return keyName;
    }
    getCommandPayloadToUpdateAttribute(data: {
        key: string,
        attributes: {
            attributeKey: string;
            value: string
        }[]
    }): DynamicImageAttributeInsertExecutePayload {
        return new DynamicImageAttributeInsertExecutePayload({
            payload: data
        });
    }
    getCommandPayloadToCreate(value: string): CkeditorPayload {
        return new DynamicImageExecutePayload({
            payload: {
                key: this.key,
                label: this.label,
                src: value,
            }
        });
    }

    getCommandPayloadToCreateOrUpdate(data: {
        key: string,
        attributes: {
            attributeKey: string;
            value: string
        }[]
    }): DynamicImageCreateOrUpdateExecutePayload {
        return new DynamicImageCreateOrUpdateExecutePayload({
            payload: data
        });
    }
    getPlaceHolder(option?:{participants?:ParticipantModel[]}): string {
        // tslint:disable-next-line: max-line-length
        return `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiM1NDY3NzYiIGZpbGwtcnVsZT0ibm9uemVybyIKICAgICAgICAgIGQ9Ik0xOS4yIDVINC44QTEuOCAxLjggMCAwIDAgMyA2Ljh2MTAuOGExLjggMS44IDAgMCAwIDEuOCAxLjhoMTQuNGExLjggMS44IDAgMCAwIDEuOC0xLjhWNi44QTEuOCAxLjggMCAwIDAgMTkuMiA1em0tOSAzLjZhLjkuOSAwIDEgMSAwIDEuOC45LjkgMCAwIDEgMC0xLjh6bTcuMiA4LjFINi42MmEuNDUuNDUgMCAwIDEtLjM1NS0uNzI2bDIuMjQxLTIuODgyYS40NS40NSAwIDAgMSAuNzAxLS4wMWwxLjg5MyAyLjI3NyAyLjc4OC0zLjU5M2EuNDUuNDUgMCAwIDEgLjcxNi4wMDdsMy4xNTYgNC4yMDdhLjQ1LjQ1IDAgMCAxLS4zNi43MnoiLz4KPC9zdmc+Cg==`;
    }
}
