import { Injectable } from '@angular/core';
import { BasePolicyCollection } from '@modules/permission/base.policy-collection';

@Injectable()
export class PolicyService {
    private static policyCollections = {};

    static parsePolicy(policy: string) {
        const tmp = policy.split('::');
        return {
            policyCollection: tmp[0],
            action: tmp[1]
        };
    }

    constructor() {
    }

    registerPolicyCollection<T extends BasePolicyCollection>(policies: T[]) {        
        policies.forEach(policy => {
            PolicyService.policyCollections[policy.policyCollectionKey] = policy;
        });

        return this;
    }

    getPolicyCollection(policyCollectionKey) {
        return PolicyService.policyCollections[policyCollectionKey] || null;
    }

    getPolicyCollections() {
        return PolicyService.policyCollections;
    }

    can<T extends BasePolicyCollection>(policy: string, ...args) {
        const { policyCollection, action } = PolicyService.parsePolicy(policy);
        let policyCollectionInstance;
        policyCollectionInstance = PolicyService.policyCollections[policyCollection];
        if (!policyCollectionInstance) {
            console.warn(`The ${policyCollection} PolicyCollection is not found`)
            return false;
            // throw new Error(`The ${policyCollection} PolicyCollection is not found`);
        }
        if (!policyCollectionInstance[action]) {

            console.warn(`The ${action} of ${policyCollection} PolicyCollection is not found`)
            return false;
            // return false;
            // throw new Error(`The ${action} of ${policyCollection} PolicyCollection is not found`);
        }
        // return false;
        return policyCollectionInstance[action](...args);
    }

    has(policy: string) {

    }

    canAsync<T extends BasePolicyCollection>(policy: string, ...args) {

    }
}

