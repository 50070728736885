import { BaseModel } from '@app/core/models/base.model';
import { DynamicFieldModel } from '@app/core/models/fields/dynamic-field.model';
import { FormFieldBase } from '@app/core/models/form-field-base.model';
import { Exclude, Expose, Transform } from 'class-transformer';
import { DynamicFormDateTime } from './dynamic-form-datetime.model';
import { DynamicFormDropDown } from './dynamic-form-dropdown.model';
import { DynamicFormFile } from './dynamic-form-file.model';
import { DynamicFormNumber } from './dynamic-form-number.model';
import { DynamicFormTextBox } from './dynamic-form-textbox.model';
import { cloneDeep } from 'lodash-es';
import { DataFieldConstant } from '@app/modules/document-builder/group-dynamic-field/group-dynamic-field.constant';

export type GroupType = 'systemField' | 'customField' | 'dataField';
export type GroupSearchType = 'client' | 'property' | 'user' | 'salesperson';

export interface GroupDataFieldInterface {
    id: string;
    name: string;
    type: GroupType;
    searchType?: GroupSearchType;
    fields: FormFieldBase[];
    config?: {
        allowSearch?: boolean;
        allowChangeName?: boolean;
    };
    isDeleted?: boolean;
}

@Exclude()
export class GroupDataFieldModel extends BaseModel implements GroupDataFieldInterface {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    type: GroupType;

    @Expose()
    searchType?: GroupSearchType;

    @Expose()
    @Transform(fields => {
        if (fields && fields.length) {
            return fields.map(field => {
                switch (field.controlType) {
                    case 'textbox':
                        return new DynamicFormTextBox(field);
                    case 'file':
                        return new DynamicFormFile(field);
                    case 'number':
                        return new DynamicFormNumber(field);
                    case 'dropdown':
                        return new DynamicFormDropDown(field);
                    case 'date':
                        return new DynamicFormDateTime(field);
                    default:
                        return field;
                }
            });
        }

        return [];

    })
    fields: FormFieldBase[];

    @Expose()
    config?: {
        allowSearch?: boolean;
        allowChangeName?: boolean;
    };

    @Expose()
    isDeleted?: boolean;
    
    getMappings(){
        if(this.searchType === 'client'){
            return cloneDeep(DataFieldConstant.CLIENT_FIELDS)
        }
        if(this.searchType === 'property'){
            return cloneDeep(DataFieldConstant.ITEM_FIELDS)
        }
        if(this.searchType === 'user'){
            return cloneDeep(DataFieldConstant.USER_FIELDS)
        }
        if(this.searchType === 'salesperson'){
            return cloneDeep(DataFieldConstant.SALESPERSON_FIELDS)
        }
        return []
    }
}
