import { SignatureFieldAttributeInsertExecutePayload } from './ckeditor-payload.model';
import { ParticipantInterface, ParticipantModel } from './participant.model';
import { FormFieldBase } from '@app/core/models/form-field-base.model';
export interface SignatureAttributeConfigInterface {
    key: string;
    label: string;
    order: number;
    enabled: boolean;

}
export class SignatureAttributeConfig implements SignatureAttributeConfigInterface {
    key: string;
    label: string;
    order: number;
    enabled: boolean;
    constructor(data: SignatureAttributeConfigInterface) {
        this.key = data && data.key;
        this.label = data && data.label;
        this.order = data && data.order;
        this.enabled = data && data.enabled;
    }

}
export interface SignatureConfigInterface {
    markupId: string;
    isForward?: boolean;
    attributes: SignatureAttributeConfigInterface[];
}

export class SignatureConfig implements SignatureConfigInterface {
    markupId: string;
    isForward?: boolean;
    attributes: SignatureAttributeConfig[];


    constructor(data: SignatureConfigInterface) {
        this.markupId = data && data.markupId;
        this.isForward = data?.isForward;
        this.attributes = SignatureConfig.conbineAttributes(data && data.attributes);
    }
    static attributeDefaults(): SignatureAttributeConfig[] {
        return [
            {
                key: 'signer_name',
                label: 'Signer Name',
                order: 1,
                enabled: false
            },
            {
                key: 'signing_date',
                label: 'Signing Date',
                order: 2,
                enabled: false
            },
        ].map(item => new SignatureAttributeConfig(item));
    }
    static conbineAttributes(attributes: SignatureAttributeConfigInterface[] = []): SignatureAttributeConfig[] {
        const attributeAvailables = SignatureConfig.attributeDefaults();
        attributeAvailables.forEach(item => {
            const selected = attributes.find(attribute => attribute.key === item.key);
            if (selected) {
                item.order = selected.order;
                item.enabled = selected.enabled;
            }
        });
        return attributeAvailables;
    }
}

export class SignatureField extends FormFieldBase {
    constructor(options = {}) {
        super(options);
        this.fieldType = 'signatureField';
        this.label = options['label'] ? options['label'] : '';
        this.controlType = 'signature';
        this.config = new SignatureConfig(options['config']);
        this.count = 1;
    }
    config: SignatureConfig;

    getPlaceHolder(option?:{participants?:ParticipantModel[]}): string {
        return '';
    }

    getMarkerName(): string {
        return this.key;
    }
    getAttributePayloadForCk(): { attributeKey: string; value: any }[] {
        return [
            {
                attributeKey: 'uuid',
                value: this.config.markupId
            },
            {
                attributeKey: 'label',
                value: this.label
            },
        ];
    }
    getCommandPayloadToUpdateAttribute(data: {
        key: string,
        attributes: {
            attributeKey: string;
            value: string
        }[]
    }): SignatureFieldAttributeInsertExecutePayload {
        return new SignatureFieldAttributeInsertExecutePayload({
            payload: data
        });
    }
    static getTemplate(data:{label:string; owner?:string},configs?:{enableSingpass:boolean}):string{
        return `<figure class="redr-field ${configs?.enableSingpass ? 'field-type__singpass' :''}" spellcheck="false"  
        type="signatureField" 
         label="${data.label}" owner="${data.owner || ''}" uuid>
         <div class="redr-signature-field redr-field-child" label="${data.label}" owner="" uuid><span class="redr-field-label">${data.label}</span></div></figure>`
    }
    static default():SignatureField{
        const instance = new SignatureField();
        instance.required = true;
        return instance
    }

}

export interface SignatureDataInterface {
    id: string;
    uuid?: string;
    role: string;
}

export class SignatureData implements SignatureDataInterface {
    id: string;
    uuid: string;
    role: string;

    constructor(data: SignatureDataInterface) {
        this.id = data.id;
        this.uuid = data.uuid || '';
        this.role = data.role;
    }
}
