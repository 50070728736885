import { SelectionModel } from '@core/cdk/collections';
import {
    Component,
    OnChanges,
    OnInit,
    SimpleChanges,
    Output,
    Input,
    EventEmitter,
    OnDestroy,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { AppConstant } from '@app/app.constant';
import { GlobalService } from '@app/core/services/global.service';
import { RedrValidator } from '@app/core/validators/redr.validator';
import { UploadDirective } from '@app/modules/upload/upload.directive';
import { MESSAGE_TYPE } from '@app/shared/models/message.model';
import { ClientRoleModel } from '@app/shared/models/metadata.model';
import { OneMapResultModel } from '@app/shared/models/one-map.model';
import { MetadataApiService } from '@app/shared/services/metadata-api.service';
import { OneMapApiService } from '@app/shared/services/one-map-api.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { ClientModel } from '@app/shared/models/client.model';
import { PhoneNumber } from '@app/modules/shared/phone-number/phone-number.component';
import { CountryModel } from '@app/core/models/country.model';
import { Helper } from '@app/core/common/helper';
import { UuidService } from '@app/core/services/uuid.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IQuerySearch } from '@app/shared/models/query-search.model';
import { VendorApiService } from '@app/shared/services/vendor-api.service';
import { VendorModel } from '@app/shared/models/vendor.model';
import { of, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { VendorCreateOrUpdateDialogComponent } from '@app/modules/vendor/vendor-create-or-update-dialog/vendor-create-or-update-dialog.component';
import { PolicyService } from '../../permission/policy.service';
import { COUNTRY_LIST } from '@app/core/data/countries.data';
import { getCountryByCode, ICountry } from '@app/core/common/common.utils';
import { ContactFieldModel } from '@app/shared/models/contact.model';

@Component({
    selector: 'app-client-company-form',
    templateUrl: './client-company-form.component.html',
    styleUrls: ['./client-company-form.component.scss'],
})
export class ClientCompanyFormComponent implements OnInit, OnChanges, OnDestroy {
    @Output() requestSubmit = new EventEmitter<{
        payload: any;
        form: FormGroup;
    }>();
    @Input() client: ClientModel;
    form: FormGroup;
    clientRoles: ClientRoleModel[] = [];
    // countries: CountryModel[] = [];
    oneMapResults: OneMapResultModel[] = [];
    countries: Array<ICountry> = [];
    roleSelected = new SelectionModel<string>(true, []);
    fileUploader;
    allowedMimeType = UploadDirective.imageMineTypes;
    isCompanyLead = false;
    vendorCtrl = new FormControl();
    countryControl = new FormControl();
    maxNoteLength: number = 250;
    currNoteLength: number = 0;

    defaultCountryCode: string = 'SG';
    clientVersion: string = '2.0.0';

    isOpenPostalBoard = true;
    hasVendorPolicy = {
        view: false,
        create: false,
        update: false,
        access: false,
    };

    private _unsubscribeAll$ = new Subject<void>();

    constructor(
        private globalService: GlobalService,
        private translateService: TranslateService,
        private metadataApiService: MetadataApiService,
        private formBuilder: FormBuilder,
        private oneMapApiService: OneMapApiService,
        private uuidService: UuidService,
        private _dialog: MatDialog,
        private vendorApiService: VendorApiService,
        private dialog: MatDialog,
        private policyService: PolicyService
    ) {
        this._initForm();
        this.checkPermissionOwner();
    }

    checkPermissionOwner() {
        if (this.policyService.can('vendor::view')) {
            this.hasVendorPolicy.view = true;
        }

        if (this.policyService.can('vendor::update')) {
            this.hasVendorPolicy.update = true;
        }

        if (this.policyService.can('vendor::create')) {
            this.hasVendorPolicy.create = true;
        }

        this.hasVendorPolicy.access =
            this.hasVendorPolicy.view ||
            this.hasVendorPolicy.create ||
            this.hasVendorPolicy.update;
    }

    onVendorChange(event): void {
        if (event.value instanceof VendorModel) {
            this.vendorCtrl.setValue(event.value);
        } else if (event.value === 'createNew') {
            const dialogRef = this.dialog.open(
                VendorCreateOrUpdateDialogComponent
            );
            dialogRef.afterClosed().subscribe((res?: VendorModel) => {
                if (!res) {
                    return;
                }
                this.vendorCtrl.setValue(res);
            });
        }
    }
    removeVendor(): void {
        this.vendorCtrl.reset();
    }
    editVendor(): void {
        const dialogRef = this.dialog.open(
            VendorCreateOrUpdateDialogComponent,
            {
                data: {
                    item: this.vendorCtrl.value,
                },
            }
        );
        dialogRef.afterClosed().subscribe((res?: VendorModel) => {
            if (!res) {
                return;
            }
            this.vendorCtrl.setValue(res);
        });
    }
    ngOnInit(): void {
        // this.metadataApiService.getCountries().subscribe((countries) => {
        //     this.countries = countries;
        // });

        this.metadataApiService.getClientRoles().subscribe((data) => {
            this.clientRoles = data;
        });
        this.listenToSearchCountry();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.client) {
            this._patchValue();
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll$.next();
        this._unsubscribeAll$.complete()
    }

    numberOnly(e) {
        return Helper.numberOnly(e);
    }
    onCompanyLeadChange(event: MatCheckboxChange): void {
        this.isCompanyLead = event.checked;
        if (!event.checked) {
            this.vendorCtrl.reset();
        }
    }
    private _patchValue() {
        this.fileUploader = {
            key: this.client.logo,
        };
        if (this.client.vendor) {
            this.isCompanyLead = true;
            this.vendorCtrl.setValue(this.client.vendor);
        }

        this.form.patchValue(
            {
                name: this.client.name,
                postalCode: this.client?.fields?.postalCode,
                blockNumber: this.client?.fields?.blockNumber,
                uenNumber: this.client?.fields?.uenNumber,
                floorNumber: this.client?.fields?.floorNumber,
                unitNumber: this.client?.fields?.unitNumber,
                streetName: this.client?.fields?.streetName,
                buildingName: this.client?.fields?.buildingName,
                email: this.client?.mainContact?.email,
                contactName: this.client?.mainContact?.name,
                nricNumber: this.client?.contacts[0]?.nricNumber,
                dateOfBirth: this.client?.contacts[0]?.dateOfBirth,
                gender: this.client?.contacts[0]?.fields?.gender,
                citizenship: this.client?.contacts[0]?.fields?.citizenship,
                salutation: this.client?.contacts[0]?.fields?.salutation,
                dictrict: this.client?.contacts[0]?.fields?.dictrict,
                landline: this.client?.contacts[0]?.fields?.landline,
                fax: this.client?.contacts[0]?.fields?.fax,
                residencyStatus:
                    this.client?.contacts[0]?.fields?.residencyStatus,
                phone: new PhoneNumber({
                    phoneNumber: this.client?.mainContact?.phone,
                    countryCode: this.client?.mainContact?.countryPhoneCode,
                }),
                website: this.client.website,
                designation: this.client?.mainContact?.fields?.designation,
                // nationality: !!this.client?.fields?.nationality
                //     ? this.client?.fields?.nationality
                //     : 'SINGAPORE',

                // nationality: this.client?.fields?.nationality ?? '',
                addressLine: this.client?.fields?.addressLine ?? '',
                countryCode: this.client?.fields?.countryCode ?? '',
            },
            { emitEvent: false }
        );
        if (this.client.note) {
            this.form.patchValue({
                note: this.client.note ?? '',
            });
        }

        this.openPostalBoard(this.client?.fields?.countryCode);
        this.roleSelected.select(...this.client.roles);
    }

    private _initForm() {
        this.form = this.formBuilder.group({
            name: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(
                        AppConstant.FIELD_VALIDATION.NAME_LENGTH
                    ),
                ],
            ],
            contactName: [
                '',
                [
                    // Validators.required,
                    Validators.maxLength(
                        AppConstant.FIELD_VALIDATION.NAME_LENGTH
                    ),
                ],
            ],
            email: [
                '',
                [
                    RedrValidator.email,
                    // Validators.required,
                    Validators.maxLength(
                        AppConstant.FIELD_VALIDATION.EMAIL_LENGTH
                    ),
                ],
            ],
            phone: [
                '',
                [
                    // Validators.required,
                    Validators.maxLength(
                        AppConstant.FIELD_VALIDATION.PHONE_LENGTH
                    ),
                ],
            ],
            website: [
                '',
                [
                    Validators.maxLength(
                        AppConstant.FIELD_VALIDATION.WEBSITE_LENGTH
                    ),
                ],
            ],
            postalCode: ['', []],
            blockNumber: ['', []],
            streetName: ['', []],
            unitNumber: ['', []],
            dictrict: ['', []],
            buildingName: ['', []],
            uenNumber: ['', []],
            designation: ['', []],
            // nationality: ['', []],
            countryCode: [this.defaultCountryCode, []],
            nricNumber: ['', []],
            dateOfBirth: ['', []],
            gender: [],
            citizenship: ['', []],
            landline: ['', []],
            fax: ['', []],
            residencyStatus: [],
            salutation: [],
            floorNumber: ['', []],
            note: ['', [this.validatorNoteCKEditor]],
            addressLine: ['', []],
        });

        this.form.controls.postalCode.valueChanges
            .pipe(debounceTime(AppConstant.DEFAULT_SETTINGS.DEBOUNCE_TIME))
            .subscribe((value) => {
                if (value instanceof OneMapResultModel) {
                    this._selectPostalCode(value);
                } else {
                    this._searchPostalCode(value);
                }
            });

        this.form.controls.note.valueChanges
            .subscribe((value) => {
                const textConvertFromValue = Helper.getContentCKEditor(value);
                this.currNoteLength = textConvertFromValue.length;
            });

        this.form.controls.countryCode?.valueChanges
            .subscribe((value) => {
                this.openPostalBoard(value);
            });
    }

    validatorNoteCKEditor = (control: FormControl): ValidationErrors | null => {
        const value = control.value;
        const textConvertFromValue = Helper.getContentCKEditor(value, {trimValue: true});
        const textLength = textConvertFromValue.length;

        if (textLength > this.maxNoteLength) return {
            max: {
                max: this.maxNoteLength,
                actual: textLength,
            }
        }

        if (!this.isCompanyLead) return null;

        if (!textLength) return {
            required: true,
        };

        return null;
    }

    private _searchPostalCode(value: string) {
        if (value) {
            this.oneMapApiService
                .search({
                    searchVal: value,
                    returnGeom: 'N',
                    getAddrDetails: 'Y',
                    // pageNum: '1'
                })
                .subscribe(
                    (data: {
                        found: number;
                        pageNum: number;
                        totalNumPages: number;
                        results: OneMapResultModel[];
                    }) => {
                        this.oneMapResults = data.results
                            .filter((v) => v.POSTAL !== 'NIL')
                            .map((v) => OneMapResultModel.fromJson(v));
                    }
                );
        }
    }

    private _removeDataNullFromOneMapAddress(
        data: OneMapResultModel
    ): OneMapResultModel {
        Object.keys(data).forEach((key) => {
            if (data[key] === 'NIL') {
                data[key] = '';
            }
        });

        return data;
    }

    private _selectPostalCode(data: OneMapResultModel) {
        const temp = this._removeDataNullFromOneMapAddress(data);
        this.form.patchValue({
            blockNumber: temp.BLK_NO,
            streetName: temp.ROAD_NAME,
            buildingName: temp.BUILDING,
            unitNumber: null,
        });
    }

    onFinishedFile(file) {
        if (file.completed) {
            this.fileUploader = file;
        } else {
            this.globalService.message.next({
                type: MESSAGE_TYPE.error,
                message: this.translateService.instant(file.error.message),
            });
        }
    }
    searchWithFn(filters: IQuerySearch) {
        return this.vendorApiService.search(filters);
    }
    submit() {
        if (this.form.invalid) {
            return;
        }

        const rawData = this.form.getRawValue();
        let postalCode = '';
        if (rawData.postalCode instanceof OneMapResultModel) {
            postalCode = rawData.postalCode.POSTAL;
        } else {
            postalCode = rawData.postalCode;
        }
        const profile = rawData.phone as PhoneNumber;
        const vendor = this.vendorCtrl.value;
        let fields: Record<string, any> = {
            uenNumber: rawData.uenNumber,
            countryCode: rawData.countryCode,
            // nationality: rawData?.nationality,
            version: this.clientVersion,
        };
        if (this.isOpenPostalBoard) {
            fields = {
                ...fields,
                postalCode,
                blockNumber: rawData.blockNumber,
                unitNumber: rawData.unitNumber,
                streetName: rawData.streetName,
                buildingName: rawData.buildingName,
                floorNumber: rawData.floorNumber,
            }
        } else {
            fields['addressLine'] = rawData.addressLine;
        }

        const data = {
            type: AppConstant.CUSTOMER_TYPE.COMPANY,
            name: rawData.name,
            roles: this.roleSelected.selected,
            website: rawData.website || '',
            fields: ContactFieldModel.fromJson(fields),
            contacts: [
                {
                    name: rawData.contactName,
                    email: rawData.email,
                    phone: profile?.phoneNumber ?? null,
                    countryPhoneCode: profile?.countryCode ?? this.client?.mainContact?.countryPhoneCode ?? null,
                    nricNumber: rawData.nricNumber,
                    dateOfBirth: rawData.dateOfBirth,
                    passportNumber: rawData.passportNumber,
                    fields: {
                        designation: rawData.designation,
                        gender: rawData.gender,
                        citizenship: rawData.citizenship,
                        landline: rawData.landline,
                        fax: rawData.fax,
                        residencyStatus: rawData.residencyStatus,
                        salutation: rawData.salutation,
                        dictrict: this.isOpenPostalBoard ? rawData.dictrict : '',
                    },
                },
            ],
            vendorId: vendor && vendor.id ? vendor.id : null,
            note: '',
        };

        if (Helper.getContentCKEditor(rawData.note, {trimValue: true}).length) {
            if (rawData.note.match(/^<div[^>]*>[\s\S]*<\/div>$/)) {
                data.note = rawData.note;
            } else {
                data.note = `<div class="ck ck-content ck-print">${rawData.note}</div>`
            }
        }

        if (this.fileUploader) {
            data['logo'] = this.fileUploader.key;
            data.contacts[0]['avatar'] = this.fileUploader.key;
        }

        if (this.vendorCtrl.value) {
            data['vendorId'] = this.vendorCtrl.value.id;
        }
        this.requestSubmit.emit({
            payload: data,
            form: this.form,
        });
    }

    displayWith(value: OneMapResultModel): string {
        if (value instanceof OneMapResultModel) {
            return value.POSTAL;
        } else {
            return value;
        }
    }
    customizeFileNameFn(file: File): string {
        return `${this.uuidService.generate()}`;
    }

    listenToSearchCountry(): void {
        this.countryControl.valueChanges
            .pipe(startWith(""), takeUntil(this._unsubscribeAll$))
            .subscribe((value) => {
                this.countries = this.filterCountries(value);
                console.log(this.countries);
            });
    }

    filterCountries(value: string): Array<ICountry> {
        if (!value) return COUNTRY_LIST;
        const filterValue = value.toLowerCase();
        const countriess: Array<ICountry> = COUNTRY_LIST;
        return countriess.filter(
            (o) => o.country.toLowerCase().includes(filterValue)
                || o.countryCode.toLowerCase().includes(filterValue)
        );
    }

    openPostalBoard(value: string) {
        this.isOpenPostalBoard = value && value.toLowerCase() === "sg";
    }
}
