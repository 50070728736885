import { BaseModel } from './base.model';
import { Expose, Exclude, Transform } from 'class-transformer';
import { Default } from '@core/decorators/default.decorator';

export interface PaginationInterface {
    limit: number;
    page: number;
    pageCount: number;
    total: number;
    categories: any;
    pageSizeOptions: number[];
}
@Exclude()
export class PaginationModel extends BaseModel implements PaginationInterface {
    @Expose({ name: 'itemsPerPage' })
    @Default(10)
    limit: number;

    @Expose()
    @Default(1)
    page: number;

    @Expose({ name: 'totalPages' })
    pageCount: number;

    @Expose({ name: 'totalItems' })
    @Default(0)
    total: number;

    @Expose()
    categories: any;

    @Expose()
    @Default([ 24, 36, 48])
    pageSizeOptions: number[];
}
