import { BaseModel } from '@app/core/models/base.model';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { Default } from '@app/core/decorators/default.decorator';
import { ParticipantModel } from './participant.model';
import {
    DocumentReminderSettingInterface,
    DocumentReminderSettingModel,
} from './document-reminder-setting.model';
import { DocumentExpirationSettingModel } from './document-expiration-setting.model';
import { PhoneNumber } from '@app/modules/shared/phone-number/phone-number.component';
import { SalespersonDataModel } from './data-gov.model';
import {
    GroupDataFieldInterface,
    GroupDataFieldModel,
} from './group-data-field.model';

export interface OrganizationMetadataInterface {
    industryId: number;
    companySize: string;
}

@Exclude()
export class OrganizationMetadataModel
    extends BaseModel
    implements OrganizationMetadataInterface
{
    @Expose()
    industryId: number;
    @Expose()
    companySize: string;
    @Expose()
    @Default(new SalespersonDataModel())
    @Type(() => SalespersonDataModel)
    agencyInfo: SalespersonDataModel;
    @Expose()
    phone: PhoneNumber;
    @Expose()
    ownerInfo: {
        phoneNumber: PhoneNumber;
    };
    @Expose()
    note: string;
    @Expose()
    gstRegNo: string;
}

export interface TemplateMetadataInterface {
    mode: 'form-mode' | 'editable-mode';
    participants: ParticipantModel[];
    groupDataFields: GroupDataFieldInterface[];
    eSigning?: DocumentESigningModel;
    groupIdOrders: string[];
    version?:string;
}

@Exclude()
export class TemplateMetadataModel
    extends BaseModel
    implements TemplateMetadataInterface
{
    @Expose()
    @Transform((val) =>{

        let data:string;
        
        if(val){
            if(typeof val === 'string'  && ['form-mode' , 'editable-mode'].includes(val)){
                data = val
            }else if(typeof val === 'object' && val['mode']){
                data = val['mode']
            }
            
        }
        return data
    })
    mode: 'form-mode' | 'editable-mode';

    @Expose()
    @Default([])
    @Type(() => ParticipantModel)
    participants: ParticipantModel[];

    @Expose()
    @Default([])
    @Type(() => GroupDataFieldModel)
    groupDataFields: GroupDataFieldModel[];

    @Expose()
    @Default({})
    @Type(() => DocumentESigningModel)
    eSigning?: DocumentESigningModel;

    @Expose()
    @Default([])
    groupIdOrders: string[];

    @Expose()
    version?:string;
}

export interface DocumentESigningInterface {
    enableESigning: boolean;
    provider: string[];
}

export class DocumentESigningModel
    extends BaseModel
    implements DocumentESigningInterface
{
    enableESigning: boolean;
    provider: string[];
}

export interface DocumentMetadataInterface  {
    mode: 'form-mode' | 'editable-mode';
    note: string;
    reminderSetting: DocumentReminderSettingInterface;
    expirationSetting: DocumentExpirationSettingModel;
    videoCall: DocumentVideoCallModel;
    eSigning: DocumentESigningModel;
    caseRefId?: number;
    invoiceRefId?: number;
    contractDocHash?: string;
    participantGroupInfo: {
        key: number; // = priority
        label: string;
    }[];
    latestNdiSignedUrl?: string;
    groupDataFields: GroupDataFieldInterface[];
    groupIdOrders: string[];
    channelConfigs?: {
        channelId: string;
        ownerId: string;
    };

    version?:string;
    
}

export class DocumentMetadataModel
    extends BaseModel
    implements DocumentMetadataInterface
{
    @Default('editable-mode')
    @Expose()
    mode: 'form-mode' | 'editable-mode';

    @Expose()
    note: string;

    @Expose()
    @Type(() => DocumentReminderSettingModel)
    reminderSetting: DocumentReminderSettingModel;

    @Expose()
    @Type(() => DocumentExpirationSettingModel)
    expirationSetting: DocumentExpirationSettingModel;

    @Expose() docType: string;

    @Expose()
    caseRefId: number;

    @Expose()
    invoiceRefId: number;

    @Expose()
    @Default([])
    @Type(() => GroupDataFieldModel)
    groupDataFields: GroupDataFieldModel[];

    videoCall: DocumentVideoCallModel;

    @Default({})
    @Type(() => DocumentESigningModel)
    eSigning: DocumentESigningModel;

    contractDocHash?: string;

    latestNdiSignedUrl?: string;

    participantGroupInfo: {
        key: number; // = priority
        label: string;
    }[];

    @Expose()
    @Default(0)
    noOfReminders = 0;

    @Expose()
    groupIdOrders: string[];

    @Expose()
    @Default({})
    channelConfigs: {
        channelId: string;
        ownerId: string;
    };

    @Expose()
    version?:string
}

export class InvoiceMetadataModel extends DocumentMetadataModel {
    @Expose() docType = 'invoice';
}

export interface DocumentVideoCallModel {
    enableVideoCall: boolean;
    provider: string;
    timezone: string;
    startTime: string;
}

export class DocumentVideoCallModel {
    enableVideoCall: boolean;
    provider: string;
    timezone: string;
    startTime: string;
}

export interface GeneralKeyInterface {
    key: string;
    displayName: string;
}

export class GeneralKeyModel implements GeneralKeyInterface {
    key: string;
    displayName: string;

    constructor(data: GeneralKeyInterface) {
        this.key = data.key || '';
        this.displayName = data.displayName || '';
    }
}

export class ClientTypeModel extends GeneralKeyModel {}

export class ClientRoleModel extends GeneralKeyModel {}

export class ClientStatusModel extends GeneralKeyModel {}

export class DocumentStatusModel extends GeneralKeyModel {}

export class InvoiceStatusModel extends GeneralKeyModel {}

export class SupplierInvoiceStatusModel extends GeneralKeyModel {}

export interface DocumentByClientMetaInterface {
    commissionAmount: {
        outstanding: number;
        received: number;
    };
    depositAmount: number;
    total: number;
    totalDuration: number;
}

export class DocumentByClientMetaModel
    extends BaseModel
    implements DocumentByClientMetaInterface
{
    @Expose()
    commissionAmount: {
        outstanding: number;
        received: number;
    };

    @Expose()
    @Default(0)
    depositAmount: number;

    @Expose()
    @Default(0)
    total: number;

    @Expose()
    @Default(0)
    totalDuration: number;
}

export interface InvoiceMetaInterface {
    outstanding: number;
    outstandingAmount: number;
    totalTaxAmount: number;
    subTotalAmount: number;
    totalItems: number;
}

@Exclude()
export class InvoiceMetaModel
    extends BaseModel
    implements InvoiceMetaInterface
{
    @Expose()
    @Default(0)
    outstanding: number;

    @Expose()
    @Default(0)
    outstandingAmount: number;

    @Expose()
    @Default(0)
    totalTaxAmount: number;

    @Expose()
    @Default(0)
    totalItems: number;

    @Expose()
    @Default(0)
    subTotalAmount: number;
}
export interface SupplierInvoiceMetaInterface {
    totalUnPaidAmount: number;
    totalPaidAmount: number;
    total: number;
    totalAmount: number;
}
@Exclude()
export class SupplierInvoiceMetaModel
    extends BaseModel
    implements SupplierInvoiceMetaInterface
{
    @Expose()
    @Default(0)
    totalUnPaidAmount: number;

    @Expose()
    @Default(0)
    totalPaidAmount: number;

    @Expose({ name: 'totalItems' })
    @Default(0)
    total: number;

    @Expose()
    @Default(0)
    totalAmount: number;
}
