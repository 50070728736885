import { FormFieldBase } from '@app/core/models/form-field-base.model';
import { DynamicField } from './dynamic-field.model';

export class DynamicFormTextBox extends DynamicField {
    controlType = 'textbox';
    type: string;

    constructor(options: {} = {}) {
        super(options);
        this.type = options['type'] || '';
    }
    transformData(input:any):any{
        if(input === null || input === undefined){
            return ''
        }
        return String(input)
    }
}
