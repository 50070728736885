import { Injectable } from '@angular/core';
import { interval, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthApiService } from "@shared/services/auth-api.service";

import { GlobalService } from './global.service';
import { AppConstant } from '@app/app.constant';
import { AuthService } from "@modules/auth/auth.service";

@Injectable({
  providedIn: 'root',
})
export class TokenCheckerService {
  private CHECK_INTERVAL = 30000; // Kiểm tra mỗi 30 giây
  private EXPIRY_BUFFER = 2 * 60; // Refresh trước 2 phút khi token hết hạn
  private REFRESH_LOCK_KEY = 'refresh_token_lock'; // Key cho trạng thái refresh
  private LAST_REFRESH_KEY = 'last_token_refresh'; // Key cho thời gian refresh gần nhất
    currentToken:string
    refreshToken: string;
  constructor(
    private globalService: GlobalService,
    private authApiService: AuthApiService,
    private authService: AuthService,
) {
  }
  init(){
    this.startTokenChecker();
    this.listenForStorageChanges();
  }
  private startTokenChecker(): void {
    console.log('TokenCheckerService start')
    this.globalService.storage
    .watch(AppConstant.GLOBAL_STORAGE.TOKEN)
    .subscribe(token => {
        this.currentToken = token
    });
    this.globalService.storage
      .watch(AppConstant.GLOBAL_STORAGE.REFRESH_TOKEN)
      .subscribe((token) => {
        this.refreshToken = token;
      });
    interval(this.CHECK_INTERVAL).pipe(
        filter(()=> !!this.currentToken)
    ).subscribe(() => {
      const token = this.currentToken;
      if (token && !this.isRefreshLocked()) {
        const expiry = this.getTokenExpiry(token);
        const currentTime = Math.floor(Date.now() / 1000);

        if (expiry && expiry - currentTime < this.EXPIRY_BUFFER) {
          // Khóa việc refresh token
          this.setRefreshLock(true);

          // Gọi refresh token
          this.authApiService
          .refreshToken({ refreshToken: this.refreshToken }).subscribe(
            (res) => {
              if (res) {
                this.authService.setLoggedIn(res);
                this.updateLastRefreshTime();
              }
              this.setRefreshLock(false); // Mở khóa sau khi refresh thành công
            },
            () => {
              this.setRefreshLock(false); // Mở khóa nếu refresh thất bại
            }
          );
        }
      }
    });
  }

  private listenForStorageChanges(): void {
    // Lắng nghe các thay đổi trong LocalStorage từ các tab khác
    fromEvent(window, 'storage').subscribe((event: any) => {
      if (event.key === this.LAST_REFRESH_KEY) {
        console.log('Refresh token được đồng bộ từ một tab khác.');
      }
    });
  }

  private getTokenExpiry(token: string): number | null {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.exp;
    } catch (e) {
      return null;
    }
  }

  private isRefreshLocked(): boolean {
    const lockValue = localStorage.getItem(this.REFRESH_LOCK_KEY);
    return lockValue === 'true';
  }

  private setRefreshLock(isLocked: boolean): void {
    localStorage.setItem(this.REFRESH_LOCK_KEY, isLocked ? 'true' : 'false');
  }

  private updateLastRefreshTime(): void {
    localStorage.setItem(this.LAST_REFRESH_KEY, Date.now().toString());
  }
}
