import { NgModule } from "@angular/core";
import { EmptyPipe } from "./empty.pipe";
import { FileSizePipe } from "./file-size.pipe";
import { MonthPipe, DayPipe, IntervalPipe } from "./dictionary.pipe";
import {
  StatusPipe,
  DocumentStatusPipe,
  CaseStatusPipe,
  InvoiceStatusPipe,
  SubscriptionStatusPipe,
  CommissionMatrixStatusPipe,
  CommissionDistributionStatusPipe,
  DisplayInvoiceStatusPipe,
  SupplierInvoiceStatusPipe,
  DisplaySupplierInvoiceStatusPipe,
  EcddStatusPipe,
} from "./status.pipe";
import { DateTimePipe } from "./date-time.pipe";
import { FilterPipe } from "./filter.pipe";
import { HumanizePipe } from "@shared/pipes/humanize.pipe";
import { SafeSourceUrlPipe } from "./safe-source-url.pipe";
import { GetValuePipe } from "./get-value.pipe";
import {
  CaseStatusLabelPipe,
  ClientStatusLabelPipe,
  CommissionDistributionStatusLabelPipe,
  EcddStatusLabelPipe,
  VerbClientStatusLabelPipe,
} from "./label.pipe";
import { ToNumberPipe } from "./to-number.pipe";
import { SafeSourceHtmlPipe } from "@shared/pipes/safe-source-html.pipe";
import {
  GenderPipe,
  residencyStatusPipe,
  sasulationPipe,
} from "@shared/pipes/client.pipe";
import { ItemTypePipe } from "./item-type.pipe";
import { PhoneNumberLabelPipe } from "./phone-number-label.pipe";
import { FullAddressPipe } from "./full-address.pipe";
import { ReferralTypeDropdownDataTransferPipe } from "./dropdown-data-transfer.pipe";
import {
  MarketingRightsPipe,
  TransactionSubTypePipe,
  LeaseStatusPipe,
  LeaseDurationUnitPipe,
} from "./case.pipe";
import { PaymentMethodPipe } from "./receipt.pipe";
import { FindPipe } from "./find.pipe";
import { HasPipe } from "./has.pipe";
import { DifferencePipe } from "./difference.pipe";
import { ReversePipe } from "./reverse.pipe";
import { TemplateTypePipe } from "./template-type.pipe";
import { DateTimeUTCPipe } from "./date-time-utc.pipe";

@NgModule({
  imports: [],
  declarations: [
    EmptyPipe,
    FileSizePipe,
    MonthPipe,
    DayPipe,
    StatusPipe,
    DateTimePipe,
    FilterPipe,
    DocumentStatusPipe,
    CaseStatusPipe,
    InvoiceStatusPipe,
    HumanizePipe,
    SafeSourceUrlPipe,
    GetValuePipe,
    ClientStatusLabelPipe,
    ToNumberPipe,
    CaseStatusLabelPipe,
    VerbClientStatusLabelPipe,
    SafeSourceHtmlPipe,
    ItemTypePipe,
    PhoneNumberLabelPipe,
    FullAddressPipe,
    SubscriptionStatusPipe,
    IntervalPipe,
    CommissionMatrixStatusPipe,
    CommissionDistributionStatusPipe,
    CommissionDistributionStatusLabelPipe,
    ReferralTypeDropdownDataTransferPipe,
    DisplayInvoiceStatusPipe,
    SupplierInvoiceStatusPipe,
    DisplaySupplierInvoiceStatusPipe,
    GenderPipe,
    residencyStatusPipe,
    sasulationPipe,
    TransactionSubTypePipe,
    MarketingRightsPipe,
    LeaseStatusPipe,
    EcddStatusPipe,
    LeaseDurationUnitPipe,
    PaymentMethodPipe,
    EcddStatusLabelPipe,
    FindPipe,
    HasPipe,
    DifferencePipe,
    ReversePipe,
    TemplateTypePipe,
    DateTimeUTCPipe,
  ],
  exports: [
    EmptyPipe,
    FileSizePipe,
    MonthPipe,
    DayPipe,
    StatusPipe,
    DateTimePipe,
    FilterPipe,
    DocumentStatusPipe,
    InvoiceStatusPipe,
    HumanizePipe,
    SafeSourceUrlPipe,
    GetValuePipe,
    ClientStatusLabelPipe,
    ToNumberPipe,
    CaseStatusPipe,
    CaseStatusLabelPipe,
    VerbClientStatusLabelPipe,
    SafeSourceHtmlPipe,
    ItemTypePipe,
    PhoneNumberLabelPipe,
    FullAddressPipe,
    SubscriptionStatusPipe,
    IntervalPipe,
    CommissionMatrixStatusPipe,
    CommissionDistributionStatusPipe,
    CommissionDistributionStatusLabelPipe,
    ReferralTypeDropdownDataTransferPipe,
    DisplayInvoiceStatusPipe,
    SupplierInvoiceStatusPipe,
    DisplaySupplierInvoiceStatusPipe,
    GenderPipe,
    residencyStatusPipe,
    sasulationPipe,
    TransactionSubTypePipe,
    MarketingRightsPipe,
    LeaseStatusPipe,
    LeaseDurationUnitPipe,
    PaymentMethodPipe,
    EcddStatusPipe,
    EcddStatusLabelPipe,
    FindPipe,
    HasPipe,
    DifferencePipe,
    ReversePipe,
    TemplateTypePipe,
    DateTimeUTCPipe,
  ],
  providers: [],
})
export class PipesModule {}
