import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { AppConstant } from '@app/app.constant';

@Pipe({
    name: 'datetimeUTC'
})
export class DateTimeUTCPipe implements PipeTransform {

    transform(value: moment.Moment | string | Date, formatString: string = AppConstant.FORMAT_DATE_TIME, keepLocalTime?: boolean): string {
        let returnValue: moment.Moment;
        if (moment.isMoment(value)) {
            returnValue = value;
        } else {
            const momentValue = moment(value);
            if (momentValue.isValid()) {
                returnValue = momentValue;
            } else {
                return '';
            }
        }

        if (keepLocalTime) {
            //add hour for subtract when system convert timezone +8 to 0
            //timezone off set get timezone with minutes
            const currentTimeZone = -(new Date().getTimezoneOffset() / 60);
            returnValue = returnValue.clone().add(currentTimeZone, 'hours').utc();
        } else {
            returnValue = returnValue.utc();
        }
        return returnValue.format(formatString);
    }
}
