import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import { PermissionService } from '@modules/permission/permission.service';
import { Observable } from 'rxjs';
import { GlobalService } from '@core/services/global.service';
import { AuthApiService } from '@shared/services/auth-api.service';
import { UserResolver } from '@core/resolvers/user.resolver';

@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild {
    constructor(private permissionService: PermissionService,
                private globalService: GlobalService,
                private authApiService: AuthApiService,
                private userResolver: UserResolver,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return new Observable(observer => {
            this.userResolver.loadUserProfile().subscribe(() => {                
                if (this.permissionService.hasPermissions(route.data.permissions)) {
                    observer.next(true);
                    observer.complete();
                } else {
                    observer.next(this.router.createUrlTree(['403'], {skipLocationChange: true}));
                    observer.complete();
                }
            });
        });
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return new Observable(observer => {
            this.userResolver.loadUserProfile().subscribe(() => {
                if (this.permissionService.hasPermissions(childRoute.data.permissions)) {
                    observer.next(true);
                    observer.complete();
                } else {
                    observer.next(this.router.createUrlTree(['403'], {skipLocationChange: true}));
                    observer.complete();
                }
            });
        });
    }
}
