<form
    id="my-form"
    #ngForm="ngForm"
    [formGroup]="form"
    (ngSubmit)="submit()"
    class="flex flex-col flex-auto gap-4"
>
    <div
        class="flex flex-col md:flex-row md:justify-start md:items-center gap-4 client-type"
    >
        <div class="title">{{ 'client.client_type' | translate }}</div>
        <div class="flex flex-col md:flex-row md:gap-4">
            <mat-checkbox
                class="example-margin"
                *ngFor="let role of clientRoles"
                [checked]="roleSelected.isSelected(role.key)"
                (change)="roleSelected.toggle(role.key)"
            >
                {{ role.displayName }}
            </mat-checkbox>
        </div>
    </div>

    <div class="flex flex-col flex-auto">
        <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4">
            <div class="flex flex-col gap-4">
                <div class="title">{{ 'common.general_info' | translate }}</div>
                <div class="flex flex-col justify-start">
                    <div
                        class="flex flex-row justify-start items-center gap-6 mb-4 img-wrapper"
                    >
                        <div class="img-preview img-preview--circle w-16 h-16">
                            <img appLoadImage [key]="fileUploader?.key" />
                        </div>
                        <button
                            type="button"
                            mat-button
                            class="btn-border"
                            (click)="inputFile.click()"
                        >
                            {{ 'customer.upload_company_logo' | translate }}
                        </button>
                        <input
                            class="input-file"
                            type="file"
                            #inputFile
                            [multiple]="false"
                            appUpload
                            [isUploadImmediately]="true"
                            [maxFiles]="1"
                            [isReplaced]="true"
                            (finishedFile)="onFinishedFile($event)"
                            [allowedMimeType]="allowedMimeType"
                            [customizeFileNameFn]="
                                customizeFileNameFn.bind(this)
                            "
                        />
                    </div>
                    <mat-form-field class="full-width">
                        <mat-label>{{
                            'customer.company_name_label'
                                | translate
                                | titlecase
                        }}</mat-label>
                        <input
                            autocomplete="off"
                            formControlName="name"
                            matInput
                            #name
                            placeholder="{{
                                'customer.company_name_placeholder' | translate
                            }}"
                            required
                            type="text"
                        />
                        <mat-error
                            *ngIf="form.controls['name'].hasError('required')"
                        >
                            {{ 'validator.required' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="
                                form.controls.name.errors &&
                                form.controls.name.errors.maxlength
                            "
                        >
                            {{
                                'validator.max_length'
                                    | translate
                                        : {
                                              value: form.controls.name.errors
                                                  .maxlength.requiredLength
                                          }
                            }}</mat-error
                        >
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <mat-label>{{
                            'customer.uen_number' | translate
                        }}</mat-label>
                        <input
                            autocomplete="off"
                            formControlName="uenNumber"
                            matInput
                            placeholder="{{
                                'customer.enter_uen_number' | translate
                            }}"
                            type="text"
                        />
                        <mat-error
                            *ngIf="
                                form.controls['uenNumber'].hasError('required')
                            "
                        >
                            {{ 'validator.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <!-- <mat-form-field class="full-width">
                        <mat-label>{{
                            'customer.country' | translate | titlecase
                        }}</mat-label>
                        <input
                            autocomplete="off"
                            formControlName="nationality"
                            matInput
                            placeholder="{{
                                'customer.enter_country' | translate
                            }}"
                            type="text"
                        />
                        <mat-error
                            *ngIf="
                                form.controls['nationality'].hasError(
                                    'required'
                                )
                            "
                        >
                            {{ 'validator.required' | translate }}
                        </mat-error>
                    </mat-form-field> -->
                    <mat-form-field class="full-width">
                        <mat-label>
                            {{'customer.country' | translate | titlecase}}
                        </mat-label>
                        <mat-select
                            [placeholder]="'business_unit.country_placeholder' | translate"
                            formControlName="countryCode"
                        >
                            <mat-option>
                                <ngx-mat-select-search
                                    [noEntriesFoundLabel]="' '"
                                    [formControl]="countryControl"
                                    [placeholderLabel]="('common.search'| translate) + '...'"
                                >
                                </ngx-mat-select-search>
                            </mat-option>

                            <mat-option
                                *ngFor="let country of countries"
                                [value]="country.countryCode"
                            >
                                {{country.country}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngIf="isOpenPostalBoard">
                        <mat-form-field class="full-width">
                            <mat-label>{{
                                'singapore_address.postal_code'
                                    | translate
                                    | titlecase
                            }}</mat-label>
                            <input
                                autocomplete="off"
                                formControlName="postalCode"
                                matInput
                                placeholder="{{
                                    'singapore_address.enter_postal_code'
                                        | translate
                                }}"
                                type="text"
                                [matAutocomplete]="postalCode"
                            />

                            <mat-autocomplete
                                autoActiveFirstOption
                                #postalCode="matAutocomplete"
                                [displayWith]="displayWith"
                            >
                                <mat-option
                                    *ngFor="let option of oneMapResults"
                                    [value]="option"
                                    [matTooltip]="option.ADDRESS"
                                >
                                    {{ option.ADDRESS }}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error
                                *ngIf="
                                    form.controls['postalCode'].hasError('required')
                                "
                            >
                                {{ 'validator.required' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <mat-label>{{
                                'singapore_address.block_number'
                                    | translate
                                    | titlecase
                            }}</mat-label>
                            <input
                                autocomplete="off"
                                formControlName="blockNumber"
                                matInput
                                placeholder="{{
                                    'singapore_address.enter_block_number'
                                        | translate
                                }}"
                                type="text"
                            />

                            <mat-error
                                *ngIf="
                                    form.controls['blockNumber'].hasError(
                                        'required'
                                    )
                                "
                            >
                                {{ 'validator.required' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <mat-label>{{
                                'singapore_address.street_name'
                                    | translate
                                    | titlecase
                            }}</mat-label>
                            <input
                                autocomplete="off"
                                formControlName="streetName"
                                matInput
                                placeholder="{{
                                    'singapore_address.enter_street_name'
                                        | translate
                                }}"
                                type="text"
                            />

                            <mat-error
                                *ngIf="
                                    form.controls['streetName'].hasError('required')
                                "
                            >
                                {{ 'validator.required' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <mat-label>{{
                                'singapore_address.floor_number'
                                    | translate
                                    | titlecase
                            }}</mat-label>
                            <input
                                autocomplete="off"
                                formControlName="floorNumber"
                                matInput
                                placeholder="{{
                                    'singapore_address.enter_floor_number'
                                        | translate
                                }}"
                                type="text"
                            />
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <mat-label>{{
                                'singapore_address.unit_number'
                                    | translate
                                    | titlecase
                            }}</mat-label>
                            <input
                                autocomplete="off"
                                formControlName="unitNumber"
                                matInput
                                placeholder="{{
                                    'singapore_address.enter_unit_number'
                                        | translate
                                }}"
                                type="text"
                            />
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <mat-label
                                >{{
                                    'singapore_address.building_name'
                                        | translate
                                        | titlecase
                                }}
                            </mat-label>
                            <input
                                autocomplete="off"
                                formControlName="buildingName"
                                matInput
                                placeholder="{{
                                    'singapore_address.enter_building_name'
                                        | translate
                                }}"
                                type="text"
                            />
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <mat-label
                                >{{
                                    'singapore_address.district'
                                        | translate
                                        | titlecase
                                }}
                            </mat-label>
                            <input
                                autocomplete="off"
                                formControlName="dictrict"
                                matInput
                                (keypress)="numberOnly($event)"
                                placeholder="{{
                                    'singapore_address.enter_district' | translate
                                }}"
                                type="text"
                            />
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!isOpenPostalBoard">
                        <mat-form-field class="full-width">
                            <mat-label
                                >{{
                                    'Address'
                                        | translate
                                        | titlecase
                                }}
                            </mat-label>
                            <input
                                autocomplete="off"
                                formControlName="addressLine"
                                matInput
                                placeholder="{{
                                    'singapore_address.enter_address'
                                        | translate
                                }}"
                                type="text"
                            />

                            <mat-error *ngIf="form.get('addressLine')?.hasError('required')">
                                {{
                                    'validator.required'
                                        | translate
                                }}
                            </mat-error>
                            <mat-error *ngIf="form.get('addressLine')?.hasError('maxlength')">
                                {{
                                    'validator.max_length'
                                        | translate
                                }}
                            </mat-error>
                        </mat-form-field>
                    </ng-container>
                    <mat-form-field class="full-width">
                        <mat-label>{{
                            'common.website' | translate
                        }}</mat-label>
                        <input
                            autocomplete="off"
                            formControlName="website"
                            matInput
                            #website
                            placeholder="{{
                                'common.website_placeholder'
                                    | translate
                                    | titlecase
                            }}"
                            type="text"
                        />
                        <mat-error
                            *ngIf="
                                form.controls.website.errors &&
                                form.controls.website.errors.maxlength
                            "
                        >
                            {{
                                'validator.max_length'
                                    | translate
                                        : {
                                              value: form.controls.website
                                                  .errors.maxlength
                                                  .requiredLength
                                          }
                            }}</mat-error
                        >
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <mat-label>
                            {{'common.note' | translate}}
                        </mat-label>
                        <div class="relative flex flex-col justify-end items-end">
                            <div
                                class="w-full"
                                formControlName="note"
                                #clientNote
                                [placeholder]="
                                    'common.note_placeholder'
                                    | translate
                                    | titlecase
                                "
                                redMatContenteditable
                                contenteditable="true"
                                [required]="isCompanyLead"
                            >
                            </div>
                            <span
                                class="absolute translate-y-6"
                                [ngClass]="{
                                    'text-red-600': form.controls['note'].hasError('max')
                                        || form.controls['note'].hasError('required')
                                }"
                            >
                                {{currNoteLength}} / {{maxNoteLength}}
                            </span>
                        </div>
                        <mat-error *ngIf="form.controls['note'].hasError('required')">
                            {{ 'validator.required' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <div class="flex flex-col">
                        <mat-checkbox
                            [disabled]="!hasVendorPolicy.access"
                            [checked]="isCompanyLead"
                            (change)="onCompanyLeadChange($event)"
                            class="mb-4"
                            >Company Lead</mat-checkbox
                        >

                        <mat-form-field *ngIf="isCompanyLead" class="w-full">
                            <mat-label>{{
                                'common.vendor' | translate | titlecase
                            }}</mat-label>

                            <mat-select
                                (selectionChange)="onVendorChange($event)"
                                [disabled]="!hasVendorPolicy.access"
                            >
                                <mat-select-trigger> </mat-select-trigger>
                                <mat-option>
                                    <ngx-mat-select-search
                                        [noEntriesFoundLabel]="' '"
                                        [formControl]="
                                            receiverSearcher.searchCtrl
                                        "
                                        [searching]="receiverSearcher.loading"
                                        placeholderLabel="Search..."
                                    >
                                    </ngx-mat-select-search>
                                </mat-option>
                                <cdk-virtual-scroll-viewport
                                    #receiverSearcher="virtualScrollController"
                                    redVirtualScrollController
                                    [searchWith]="searchWithFn.bind(this)"
                                >
                                    <ng-container
                                        *cdkVirtualFor="
                                            let item of receiverSearcher.results
                                        "
                                    >
                                        <mat-option [value]="item">
                                            <div
                                                class="flex flex-col w-full overflow-hidden"
                                            >
                                                <div class="truncate leading-4">
                                                    {{ item?.name }}
                                                    <span
                                                        class="text-orange-800 text-theme-gray italic"
                                                        *ngIf="
                                                            item?.metadata
                                                                ?.inUse
                                                        "
                                                        >In Use |
                                                        {{
                                                            item.metadata.role
                                                                | titlecase
                                                        }}</span
                                                    >
                                                </div>
                                                <div
                                                    class="truncate text-theme-gray leading-4"
                                                >
                                                    {{ item?.email }}
                                                </div>
                                            </div>
                                        </mat-option>
                                    </ng-container>
                                </cdk-virtual-scroll-viewport>
                                <mat-option
                                    value="createNew"
                                    *ngIf="hasVendorPolicy.create"
                                >
                                    Create New
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div
                            *ngIf="vendorCtrl.value as vendorSelected"
                            class="flex flex-col w-full"
                        >
                            <div
                                class="redoc-card border border-default flex p-4 flex-col flex-auto gap-2 relative"
                            >
                                <div
                                    *ngIf="hasVendorPolicy.access"
                                    class="absolute h-10 right-0 top-0"
                                >
                                    <button
                                        type="button"
                                        *ngIf="hasVendorPolicy.update"
                                        mat-icon-button
                                        (click)="editVendor()"
                                    >
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button
                                        type="button"
                                        mat-icon-button
                                        (click)="removeVendor()"
                                    >
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                </div>
                                <div class="flex flex-row">
                                    <span class="text-color-gray mr-2"
                                        >Company Name: </span
                                    ><span>{{
                                        vendorSelected.name | empty
                                    }}</span>
                                </div>

                                <div class="flex flex-row">
                                    <span class="text-color-gray mr-2"
                                        >Code: </span
                                    ><span>{{
                                        vendorSelected.code | empty
                                    }}</span>
                                </div>
                                <div class="flex md:flex-row flex-col">
                                    <span class="text-color-gray mr-2"
                                        >Agent's commission referred from this
                                        vendor will be accumulated? </span
                                    ><span>{{
                                        vendorSelected.promoGCE ? 'Yes' : 'No'
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col gap-4">
                <div class="title">
                    {{ 'customer.contact_person' | translate }}
                </div>
                <div>
                    <mat-form-field class="full-width">
                        <mat-label>{{
                            'common.name' | translate | titlecase
                        }}</mat-label>
                        <input
                            autocomplete="off"
                            formControlName="contactName"
                            matInput
                            #contactName
                            placeholder="{{
                                'customer.person_name_placeholder' | translate
                            }}"
                            type="text"
                        />
                        <mat-error
                            *ngIf="
                                form.controls['contactName'].hasError(
                                    'required'
                                )
                            "
                        >
                            {{ 'validator.required' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="
                                form.controls.contactName.errors &&
                                form.controls.contactName.errors.maxlength
                            "
                        >
                            {{
                                'validator.max_length'
                                    | translate
                                        : {
                                              value: form.controls.contactName
                                                  .errors.maxlength
                                                  .requiredLength
                                          }
                            }}</mat-error
                        >
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <mat-label>{{
                            'customer.designation' | translate | titlecase
                        }}</mat-label>
                        <input
                            autocomplete="off"
                            formControlName="designation"
                            matInput
                            placeholder="{{
                                'customer.enter_designation' | translate
                            }}"
                            type="text"
                        />
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <mat-label>{{
                            'common.email' | translate | titlecase
                        }}</mat-label>
                        <input
                            autocomplete="off"
                            formControlName="email"
                            matInput
                            #email
                            placeholder="{{
                                'common.email_placeholder' | translate
                            }}"
                            type="text"
                            trim
                        />
                        <mat-error
                            *ngIf="
                                form.controls.email.errors &&
                                form.controls.email.errors.maxlength
                            "
                            >{{
                                'validator.max_length'
                                    | translate
                                        : {
                                              value: form.controls.email.errors
                                                  .maxlength.requiredLength
                                          }
                            }}</mat-error
                        >
                        <mat-error
                            *ngIf="form.controls['email'].hasError('required')"
                        >
                            {{ 'validator.required' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="form.controls['email'].hasError('email')"
                        >
                            {{ 'validator.email' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="form.controls['email'].hasError('existed')"
                        >
                            {{ 'validator.existed' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <mat-label>{{
                            'common.phone_number' | translate | titlecase
                        }}</mat-label>
                        <app-phone-number
                            formControlName="phone"
                            placeholder="{{
                                'common.phone_number_placeholder' | translate
                            }}"
                        >
                        </app-phone-number>
                        <mat-error
                            *ngIf="form.controls['phone'].hasError('required')"
                        >
                            {{ 'validator.required' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="
                                form.controls.phone.errors &&
                                form.controls.phone.errors.maxlength
                            "
                            >{{
                                'validator.max_length'
                                    | translate
                                        : {
                                              value: form.controls.phone.errors
                                                  .maxlength.requiredLength
                                          }
                            }}</mat-error
                        >
                    </mat-form-field>
                    <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4">
                        <mat-form-field class="full-width">
                            <mat-label>{{
                                'customer.id_no.' | translate
                            }}</mat-label>
                            <input
                                autocomplete="off"
                                formControlName="nricNumber"
                                matInput
                                placeholder="{{
                                    'customer.enter_id_no.' | translate
                                }}"
                                type="text"
                            />
                        </mat-form-field>
                        <mat-form-field class="full-width">
                            <mat-label>{{
                                'customer.dob' | translate
                            }}</mat-label>
                            <input
                                matInput
                                [matDatepicker]="datePicker"
                                placeholder="{{
                                    'customer.enter_date_of_birth' | translate
                                }}"
                                readonly
                                (click)="datePicker.open()"
                                (focus)="datePicker.open()"
                                formControlName="dateOfBirth"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="datePicker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker
                                #datePicker
                                startView="multi-year"
                            ></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <mat-label>{{
                                'customer.gender' | translate | titlecase
                            }}</mat-label>
                            <mat-select formControlName="gender">
                                <mat-option value="male">Male</mat-option>
                                <mat-option value="female">Female</mat-option>
                                <mat-option value="other">Other</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <mat-label>{{
                                'customer.salutation' | translate | titlecase
                            }}</mat-label>
                            <mat-select formControlName="salutation">
                                <mat-option value="mr">Mr</mat-option>
                                <mat-option value="ms">Ms</mat-option>
                                <mat-option value="mrs">Mrs</mat-option>
                                <mat-option value="others">Others</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <mat-label>{{
                                'customer.residency_status'
                                    | translate
                                    | titlecase
                            }}</mat-label>
                            <mat-select formControlName="residencyStatus">
                                <mat-option value="citizen">Citizen</mat-option>
                                <mat-option value="pr">PR</mat-option>
                                <mat-option value="foreigner"
                                    >Foreigner</mat-option
                                >
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <mat-label>{{
                                'customer.nationality' | translate | titlecase
                            }}</mat-label>
                            <input
                                autocomplete="off"
                                formControlName="citizenship"
                                matInput
                                placeholder="{{
                                    'customer.enter_nationality' | translate
                                }}"
                                type="text"
                            />
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <mat-label>{{
                                'customer.landline' | translate | titlecase
                            }}</mat-label>
                            <input
                                autocomplete="off"
                                formControlName="landline"
                                matInput
                                placeholder="{{
                                    'customer.enter_landline' | translate
                                }}"
                                type="text"
                            />
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <mat-label>{{
                                'customer.fax' | translate | titlecase
                            }}</mat-label>
                            <input
                                autocomplete="off"
                                formControlName="fax"
                                matInput
                                placeholder="{{
                                    'customer.enter_fax' | translate
                                }}"
                                type="text"
                            />
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
